class TelegramService {
    #chat_id = "-1001899599724"
    #token = "6216090042:AAFPUDOtJyDzZdshUEiBH0j0tupGI6aXA-A"
    
    async sendMessage(text) {
                var url = `https://api.telegram.org/bot${this.#token}/sendMessage`
                var headers = {
                    "Content-Type": "application/json",
                    "cache-control": "no-cache"
                }
                var data = JSON.stringify({
                    "chat_id": `${this.#chat_id}`,
                    "text": text
                })
                return await  fetch(url, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: headers,
                    body: data
                }).then(data => data.json())
    }
}

export default new TelegramService()